import React, { Component } from "react";
import { connect } from "react-redux";
import { selectedModuleBrandAndStarszone } from "../../services/Api";
import CustomIcon from "../UI-components/CustomIcon";
import {
  canImportData
} from "../../services/Api";

class AddModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImportEnabled: false,
      isExportEnabled: false
    };
  }


  componentDidMount() {
    if (
      // check if macro list is empty hance new
      (this.props.macroList && !this.props.macroList[0].moduleList.length) ||
      // check if user is admin
      (this.props.auth.grants &&
        (canImportData(this.props.auth.grants) || this.props.auth.grants[0].role.toLowerCase() === "admin"))
    ) {
      this.setState({ 
        isImportEnabled: true,
        isExportEnabled:true
      });
    }
  }

  componentDidUpdate(prevProps) {
    
    if (prevProps.macroList !== this.props.macroList) {
      const { brand, starZones } = selectedModuleBrandAndStarszone(
        this.props.macroList
      );
      const isImportEnabled = canImportData(this.props.auth.grants) &&
        this.props.auth.grants &&
        this.props.auth.grants.some((grant) => {
          if (grant.role.toLowerCase() === "admin") return true;
          if (!grant.types.includes("edit")) return false;

          let hasBrand = brand.some((moduleBrand) => {
            return grant.brands.includes(moduleBrand);
          });

          if (!brand.length) {
            hasBrand = true;
          }

          let hasStarsZones = starZones.some((moduleStarzone) => {
            return grant.starsZones.includes(moduleStarzone);
          });

          if (!starZones.length) {
            hasStarsZones = true;
          }
          return hasBrand && hasStarsZones;
        }); 
      const isExportEnabled=isImportEnabled;

      this.setState({ isImportEnabled,isExportEnabled});
    }
  }

  render() {

   let exportClusterPopup = this.props.exportClusterPopup;

    return (
      <div className="col-8 add-module-container">
        <div className="add-module modulo-outline">
          <div className="row">
            <button
              className={`col-5 add-module-half${
                this.props.disabled ? " disabled" : ""
              }`}
              onClick={() => {
                !this.props.disabled && this.props.addModuleHandler();
              }}
            >
              <CustomIcon iconClass="icon-plus" />
              <p>Create Module</p>
            </button>
            {this.props.isImportActive && (
              <div
                className={`col-4 add-module-half${
                  this.state.isImportEnabled ? "" : " disabled"
                }`}
                onClick={() => {
                  this.state.isImportEnabled &&
                    this.props.importModuleHandler();
                }}
              >
                <>
                  <p>Import Module</p>
                  <CustomIcon iconClass="icon-import" />
                </>
              </div>
            )}

            {this.props.isExportActive && (
              <div
                className={`col-3 add-module-half${
                  this.state.isExportEnabled ? "" : " disabled"
                }`}
                onClick={() => {
                  exportClusterPopup();
                  //this.props.exportModuleHandler();
                }}
              >
                <>
                  <p>Export Module</p>
                  <CustomIcon iconClass="icon-export" />
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    macroList: state.module.macroList,
    selectedFilters: state.filter.selectedFilters,
  };
};

export default connect(mapStateToProps)(AddModule);
