import React, { Component } from "react";
import CustomIcon from "../UI-components/CustomIcon";
import CustomInput from "../UI-components/CustomInput";
import ModuleJar from "./ModuleJar/ModuleJar";
import Tooltip from "../UI-components/Tooltip";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import {
    formatNumber,
    formatStatusLabel
} from "../../services/Api";
import PopupLia from "./Cluster-Lia/PopupLia";
import PopupClusterClassification from "./Cluster-Lia/PopupClusterClassification";

class ModuleListAreaContainer extends Component {
    state = {
        isBinOpen: false,
    };

   
    computeDiscoStats = (filterFun) => {
        let totDisco = 0
        let totSku = 1

        const allModules = [] // tutti i moduli che sono in stato "ADDED"
        Object.keys(this.props.listModule).forEach(family => {
            allModules.push(...this.props.listModule[family])
        })

        totDisco = allModules.map(module => {
            return module?.productDtoList.filter(filterFun).length
        }).reduce((totA, totB) => totA + totB)

        totSku = allModules.map(module => {
            return module?.productDtoList.length
        }).reduce((totA, totB) => totA + totB)

        return ({
            tot: totDisco || '-',
            perc: `${(totDisco / totSku).toFixed(3) * 100}%`
        })
    }

    toggleBin = () =>
        this.setState((prevState) => {
            return { isBinOpen: !prevState.isBinOpen };
        });

    onDragStart = (moduleI) => {
        this.props.dragStart(moduleI);
    };

    onDragEnter = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        let areaDrop = document.querySelector(`.modulePrinc`);
        areaDrop.classList.add("dragOver");
    };

    onDragLeave = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        let areaDrop = document.querySelector(`.modulePrinc`);
        areaDrop.classList.remove("dragOver");
    };

    onDragOver = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    onDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let areaDrop = document.querySelector(`.modulePrinc`);
        areaDrop.classList.remove("dragStart", "dragOver");
        this.props.dropModule();
        this.props.dragStop();
    };

    render() {
        let modules = this.props.listModule;

        return (
            <div>
                <HeaderCluster
                    title={this.props.title}
                    description={this.props.description}
                    toogleDescriptionEditMode={this.props.toogleDescriptionEditMode}
                    handleDescriptionChange={this.props.handleDescriptionChange}
                    status={this.props.status}
                    stickHeader={this.props.stickHeader}
                />
                {
                    this.state.isBinOpen ? (

                        <div
                            id="removedMod"
                            className={
                                "component half-area module-list " +
                                (!this.props.isAlternativeOpen ? " open" : "")
                            }
                        >
                            <div className="header-half-area">
                                <h2 className="title">Removed Modules</h2>
                                <Tooltip
                                    onClick={this.toggleBin}
                                    tooltipTextClass="white"
                                    tooltipClass="tooltip-cluster-comp left"
                                    text="View Cluster Composition"
                                >
                                    <CustomIcon iconClass="icon-cestino" />
                                </Tooltip>
                            </div>
                            <div className="container-module-jar">
                                {this.props.listRemoved.length > 0 ? (
                                    this.props.listRemoved.map((moduleI) => (
                                        <DragDropContainer
                                            noDragging={this.props.isDisabled || this.state.isBinOpen}
                                            key={moduleI.moduleName}
                                            targetKey="drag-drop"
                                            dragData={moduleI}
                                            onDragStart={() => {} /*this.onDragStart(moduleI)*/}
                                        >
                                            <ModuleJar
                                                moduleItem={moduleI}
                                                draggable={!this.props.isDisabled}
                                                goToSingleModule={(moduleItem) =>
                                                    this.props.goToSingleModule(moduleItem)
                                                }
                                                isRemoved
                                                addToClusterComp={(moduleI) =>
                                                    this.props.addToClusterComp(moduleI, this.props.cluster.clusterId, this.props.cluster.brandCode)
                                                }
                                            />
                                        </DragDropContainer>
                                    ))
                                ) : (
                                    <p className="msg">There aren't removed modules</p>
                                )}
                            </div>
                        </div>

                    ) : (

                        <DropTarget
                            targetKey="drag-drop"
                            onDragEnter={this.onDragEnter}
                            onDragOver={this.onDragOver}
                            onDragLeave={this.onDragLeave}
                            onHit={this.onDrop}
                        >
                            <div
                                className={
                                    (this.props.dragFrom !== null && this.props.dragFrom !== "princ"
                                        ? " dragStart"
                                        : "") + (!this.props.isAlternativeOpen ? " open" : "") + " modulePrinc "
                                }
                            >
                                <div
                                    className={
                                        "component half-area module-list " +
                                        (!this.props.isAlternativeOpen ? "open" : "")
                                    }
                                    style={{
                                        height: "100%"
                                    }}
                                >
                                    <div className="header-half-area">
                                        <h2 className="title">Module List</h2>
                                        <Tooltip
                                            onClick={this.toggleBin}
                                            tooltipTextClass="white"
                                            tooltipClass="tooltip-cluster-comp left"
                                            text="View Removed Modules"
                                        >
                                            <CustomIcon iconClass="icon-cestino" />
                                        </Tooltip>
                                    </div>
                                    <div className="container-module-jar">
                                        {Object.keys(modules).length > 0 ? (
                                            Object.keys(modules).map((key) => (
                                                <div key={key} className="family-container">
                                                    <h2>{key}</h2>
                                                    {modules[key].map((moduleI) => {
                                                        return (
                                                            <DragDropContainer
                                                                noDragging={this.props.isDisabled}
                                                                key={moduleI.moduleName}
                                                                targetKey="drag-drop"
                                                                dragData={moduleI}
                                                                onDragStart={(e) => this.onDragStart(e, moduleI)}
                                                            >
                                                                <ModuleJar
                                                                    moduleItem={moduleI}
                                                                    draggable={!this.props.isDisabled}
                                                                    goToSingleModule={(moduleItem) =>
                                                                        this.props.goToSingleModule(moduleItem)
                                                                    }
                                                                    // toggleType={() => this.props.toggleType(moduleI)}
                                                                    remove={(moduleName) =>
                                                                        this.props.remove(moduleName, this.props.cluster.clusterId)
                                                                    }
                                                                />
                                                            </DragDropContainer>
                                                        );
                                                    })}
                                                </div>
                                            ))
                                        ) : (
                                            <p className="empty-cluster">
                                                Search and drag modules to complete your new cluster
                                            </p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </DropTarget> 

                    )}

                <FooterClusterStatistics
                    rangeFacing={this.props.rangeFacing}
                    cluster={this.props.cluster}
                    listModule={this.props.listModule}
                    percObj={this.props.percObj}
                    setLiaListEdited={this.props.setLiaListEdited}
                    addClassification={this.props.addClassification}
                    computeTotDiscoAndPerc={this.computeDiscoStats}
                    setClusterLias={this.props.setClusterLias}
                />

            </div>
        )
    }
}

const HeaderCluster = (props) => {



    return (
        <div style= {{
            padding: "36px 0",
        }}>
             <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom:"0.5rem",
                backgroundColor: "#e5e5e5",
                width: props.stickHeader ? "100%" : "auto",
                zIndex: 101,
                position: props.stickHeader ? "sticky" : "unset",
                top: 0,

            }}>
                <p>{props.title}</p>
                <div className="mc-status">
                    {formatStatusLabel(props.status)}
                </div>
            </div>
            <div>
                {
                props.description.editMode || !props.description.value?
                    <CustomInput 
                        handleChange={props.handleDescriptionChange}
                        value={props.description.value}
                        placeholder="Cluster Description"
                        maxLength="100"
                    ></CustomInput> :
                    <p style={{padding:"15px 0", color: "grey", fontStyle:"italic", cursor:"text"}}
                     onClick={props.toogleDescriptionEditMode}>{props.description.value}</p> 
                }
            </div>
        </div>
    )
}

const FooterClusterStatistics = (props) => {
    return (
        <div className="cluster-footer" style={{
            height: "auto",
            padding: "36px 0"
        }}>
            <div className="box-footer">
                <p>
                    {" "}
                    Range Facing:{" "}
                    <span className="bold">
                        {props.rangeFacing}
                    </span>{" "}
                </p>
                <p>
                    {" "}
                    Door:{" "}
                    <span className="bold">
                        {props.cluster.stores &&
                            formatNumber(props.cluster.stores.length)}
                    </span>{" "}
                </p>
                {props.percObj ? (
                    <p>
                        {" "}
                        Refresh:{" "}
                        <span className="bold">
                            {parseInt(
                                props.percObj.percentageRefresh
                            ).toFixed(0)}
                            %
                        </span>{" "}
                    </p>
                ) : null}
                
                {
                    /*
                    <>
                    <p>
                        {" "}
                        Tot disco MH:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.mustHave).tot}
                        </span>{" "}
                    </p>
                    <p>
                        {" "}
                        Tot disco EL:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.eligible).tot}
                        </span>{" "}
                    </p>
                    <p>
                        {" "}
                        Tot disco MH+EL:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.eligible || sku.mustHave).tot}
                        </span>{" "}
                    </p>
                    <p>
                        {" "}
                        Tot disco MH %:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.mustHave).perc}
                        </span>{" "}
                    </p>
                    <p>
                        {" "}
                        Tot disco EL %:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.eligible).perc}
                        </span>{" "}
                    </p>
                    <p>
                        {" "}
                        Tot disco MH+EL %:{" "}
                        <span className="bold">
                            {props.listModule &&
                                props.computeTotDiscoAndPerc((sku) => sku.eligible || sku.mustHave).perc}
                        </span>{" "}
                    </p>
                    </>
                    */
                }
                <PopupLia
                    setLiaListEdited={props.setLiaListEdited}
                    clusterLias={props.cluster.lias}
                    setClusterLias={props.setClusterLias}
                    clusterId={props.cluster.clusterId}
                />
                <PopupClusterClassification
                    classificationList={props.cluster.classifications}
                    addClassification={props.addClassification}
                    clusterId={props.cluster.clusterId}
                />
            </div>
            {props.percObj ? (
                <p>
                    <span className="bold">
                        {formatNumber(props.percObj.totalSku)}
                    </span>{" "}
                    sku{" "}
                </p>
            ) : null}
        </div>
    )
}

export default ModuleListAreaContainer;
