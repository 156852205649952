import {useEffect, useState} from "react";
import Button from "../UI-components/Button";
import RadioGrp from "../UI-components/RadioGroup";
import {isFunction} from "lodash";
import {connect} from "react-redux";
import * as actions from "../../store/actions";

const emptyRowModel = {
    module: [""],
    add: [""],
    remove: [""],
    status: ["MH"],
    month: [""],
    minValueSkuList: [""],
    minValueSku: 1,
    moduleDescription: "",
};

const emptyRowModelStatus2 = {
    module: [""],
    add: [""],
    remove: [""],
    status: ["EL"],
    month: [""],
    minValueSkuList: [""],
    minValueSku: 1,
    moduleDescription: "",
};

const initialState = {
    module: [""],
    add: [""],
    remove: [""],
    status: ["MH"],
    month: [""],
    minValueSkuList: [""],
    minValueSku: 1,
    moduleDescription: "",
};

const MassiveEditModuleWITable = ({
                                      onEditRow,
                                      resetTableData,
                                      rowsToSendMassiveEdit,
                                      setResetRowsToSendMassiveEdit,
                                  }) => {
    const [inputRows, setInputRows] = useState([initialState]);

    const adaptedArr = Object.keys(rowsToSendMassiveEdit).flatMap((el) => {
        return rowsToSendMassiveEdit[el];
    });

    function resetRowsReceivedFromDiscoRemovedPage() {
        if (adaptedArr.length) {
            setResetRowsToSendMassiveEdit([]);
        }
    }

    const mappedRowsFromDiscoRemovedPage =
        adaptedArr.length &&
        adaptedArr.map((el) => {
            return {
                module: [el.moduleLabel],
                add: [""],
                remove: [el.upc],
                status: ["MH"],
                month: [el.monthId],
            };
        });

    useEffect(() => {
        if (adaptedArr.length) {
            setInputRows(mappedRowsFromDiscoRemovedPage);
        } else {
            setInputRows([
                {
                    module: [""],
                    add: [""],
                    remove: [""],
                    status: ["MH"],
                    month: [""],
                    minValueSkuList: [""],
                    minValueSku: 1,
                    moduleDescription: "",
                },
            ]);
        }
        return () => {
            setInputRows([
                {
                    module: [""],
                    add: [""],
                    remove: [""],
                    status: ["MH"],
                    month: [""],
                    minValueSkuList: [""],
                    minValueSku: 1,
                    moduleDescription: "",
                },
            ]);
            resetRowsReceivedFromDiscoRemovedPage();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resetTableData) {
            setInputRows([
                {
                    module: [""],
                    add: [""],
                    remove: [""],
                    status: ["MH"],
                    month: [""],
                    minValueSkuList: [""],
                    minValueSku: 1,
                    moduleDescription: "",
                },
            ]);
        }
    }, [resetTableData]);

    const checkIsEmpty = () => {
        const isEmpty =
            (inputRows.length >= 1 &&
                JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowModel)) ||
            JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowModelStatus2);
        return isEmpty;
    };

    // Check to enable or disableButtons
    const disableCtasModule = () => {
        const mappedmonthAndModulesFromRows =
            inputRows &&
            inputRows.map((row) => {
                return {
                    ...row
                };
            });

        const checkEmptyString =
            mappedmonthAndModulesFromRows &&
            mappedmonthAndModulesFromRows.map((el) => {
                return {
                    isEmptyMonth: el.month.includes(""),
                    isEmptyModule: el.module.includes(""),
                    isEmptyAdd: el.add.includes(""),
                    isEmptyRemove: el.remove.includes(""),
                    isEmptyMinValueSku: el.minValueSku === 1,
                    isEmptyMinValueSkuList: el.minValueSkuList.includes(""),
                    isStatusNotChanged: el.status.includes("MH"),
                    isDescriptionEmpty: !el.moduleDescription || el.moduleDescription.length === 0
                };
            });


        const checkEmptyStrings =
            checkEmptyString &&
            checkEmptyString.map((el) => {
                //Se la descrizione non è vuota allora posso salvare se l'unica campo modificato è quello del module
                const onlyDescriptionChanged = (!el.isDescriptionEmpty && el.isEmptyMonth && el.isEmptyAdd && el.isEmptyRemove && el.isEmptyMinValueSku && el.isEmptyMinValueSkuList && el.isStatusNotChanged)
                return el.isEmptyModule || (!onlyDescriptionChanged && el.isEmptyMonth)
            });

        const result = checkEmptyStrings && checkEmptyStrings.includes(true);

        return result;
    };

    const checkIfModuleTable = () => {
        return true;
    };

    const handleChange = (selectType, optionsSelected, index, event) => {
        const mapped = inputRows.map((row, rowIndex) => {
            switch (selectType) {
                case "minValueSku":
                    if (rowIndex === index) {
                        let value = parseFloat(optionsSelected);
                        if (value < 1) {
                            value = 1;
                        }
                        row[selectType] = value;
                    }
                    break;
                case "moduleDescription":
                    if (rowIndex === index) {
                        row[selectType] = optionsSelected;
                    }
                    break;
                default:
                    const inputLine = optionsSelected.split(/[\n\r]/g).length;

                    if (rowIndex === index && inputLine === 1) {
                        row[selectType] = [optionsSelected];
                    }

                    if (inputLine > 1) {
                        if (rowIndex === index) {
                            row[selectType] = [optionsSelected];
                        }
                    }
            }

            return row;
        });

        setInputRows(mapped);
    };

    const addRow = () => {
        setInputRows((prevState) => [
            {
                module: [""],
                add: [""],
                remove: [""],
                status: ["MH"],
                month: [""],
                minValueSkuList: [""],
                minValueSku: 1,
                moduleDescription: ""
            },
            ...prevState,
        ]);
    };

    const removeRow = (index) => {
        setInputRows((prevState) => {
            const x = prevState.filter(
                (p, i) => prevState.length === 1 || i !== index
            );

            if (x.length === 1 && prevState.length === 1) {
                return [
                    {
                        module: [""],
                        add: [""],
                        remove: [""],
                        status: ["MH"],
                        month: [""],
                        minValueSkuList: [""],
                        minValueSku: 1,
                        moduleDescription: ""
                    },
                ];
            }
            return x;
        });
    };

    useEffect(() => {
        if (isFunction(onEditRow))
            onEditRow(
                inputRows,
                checkIsEmpty(),
                checkIfModuleTable(),
                disableCtasModule()
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRows]);

    const getSelected = (status) => {
        return !status || status === "" ? null : status === "MH" ? 0 : 1;
    };

    const MassiveEditWITableGeneratedRows = () => {
        const radioButtonOptions = ["MH", "EL"];

        return inputRows.map((row, i) => {
            return (
                <tr key={i}>
                    <td style={{verticalAlign: "top"}}>
                  <textarea
                      style={{resize: "vertical"}}
                      className="search inputStyle "
                      placeholder="Write your module..."
                      onChange={(event) =>
                          handleChange("module", event.target.value, i, event)
                      }
                      value={row.module}
                  />
                    </td>
                    <td>
                  <textarea
                      style={{resize: "vertical"}}
                      className="search inputStyle "
                      placeholder="Description"
                      maxLength={100}
                      onChange={(event) =>
                          handleChange("moduleDescription", event.target.value, i, event)
                      }
                      value={row.moduleDescription}
                  />
                    </td>
                    <td style={{verticalAlign: "top"}}>
                  <textarea
                      style={{resize: "vertical"}}
                      className="search inputStyle "
                      placeholder="Write your sku..."
                      onChange={(event) =>
                          handleChange("add", event.target.value, i, event)
                      }
                      value={row.add}
                  />
                    </td>
                    <td style={{verticalAlign: "top"}}>
                  <textarea
                      style={{resize: "vertical"}}
                      className="search inputStyle "
                      name="remove"
                      placeholder="Write your sku..."
                      onChange={(event) =>
                          handleChange("remove", event.target.value, i, event)
                      }
                      value={row.remove}
                  />
                    </td>
                    <td style={{verticalAlign: "top"}}>
                        <RadioGrp
                            selectedIndex={getSelected(row.status[0])}
                            options={radioButtonOptions}
                            active={false}
                            handleClick={(radioButtonOptions) => {
                                handleChange("status", radioButtonOptions, i);
                            }}
                            selectedValue={row.status[0]}
                        />
                    </td>

                    <td style={{verticalAlign: "top"}}>
                  <textarea
                      style={{resize: "vertical"}}
                      className="search inputStyle "
                      placeholder="Write your month..."
                      value={row.month}
                      onChange={(event) =>
                          handleChange("month", event.target.value, i, event)
                      }
                  />
                    </td>
                    <td style={{verticalAlign: "top"}}>
                        <div className="minValueSkuRow">
                     <textarea
                         style={{resize: "vertical"}}
                         className="search inputStyle "
                         placeholder="Write your sku..."
                         onChange={(event) =>
                             handleChange("minValueSkuList", event.target.value, i, event)
                         }
                         value={row.minValueSkuList}
                     />

                            <div style={{display: "flex"}}>
                                {
                                    /*
                                    <Button btnClass="minValueSkuButton"
                                       disabled={!row.minValueSkuList?.[0] || row.minValueSku <= 1}
                                       handleClick={(event) => handleChange("minValueSku", row.minValueSku - 0.1, i, event)}
                                    >
                                       -
                                    </Button>
                                    */
                                }
                                <input
                                    className={"minValueSkuInput"}
                                    onChange={(event) => handleChange("minValueSku", event.target.value, i, event)}
                                    value={row.minValueSku}
                                    maxLength={9}
                                    type="number"
                                    step=".1"
                                    disabled={!row.minValueSkuList?.[0]}
                                    onBlur={e => {
                                        e.target.value === "" && handleChange("minValueSku", 1, i, e)
                                    }}
                                />
                                {
                                    /*
                                       <Button
                                          btnClass="minValueSkuButton"
                                          disabled={!row.minValueSkuList?.[0]}
                                          handleClick={(event) => handleChange("minValueSku", row.minValueSku + 0.1, i, event)}
                                       >
                                          +
                                       </Button>
                                    */
                                }
                            </div>
                        </div>
                    </td>
                    <td style={{verticalAlign: "center"}}>
                        {i === 0 ? (
                            <div style={{display: "flex"}}>
                                <Button handleClick={addRow}>+</Button>
                                <Button handleClick={() => removeRow(i)}>-</Button>
                            </div>
                        ) : (
                            <div style={{display: "flex"}}>
                                <Button btnClass="hideBtn" handleClick={() => {
                                }}>
                                    +
                                </Button>
                                <Button handleClick={() => removeRow(i)}>-</Button>
                            </div>
                        )}
                    </td>
                </tr>
            );
        });
    };

    return (
        <table className="massive-edit-module-table">
            <thead>
            <tr>
                <th>MODULE</th>
                <th>DESCRIPTION</th>
                <th>ADD</th>
                <th>REMOVE</th>
                <th>STATUS</th>
                <th>MONTH</th>
                <th>MIN VALUE</th>
            </tr>
            </thead>
            <tbody>{MassiveEditWITableGeneratedRows()}</tbody>
        </table>
    );
};

const mapStateToProps = (state) => {
    return {
        rowsToSendMassiveEdit: state.filter.rowsToSendMassiveEdit,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setResetRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
            dispatch(actions.setResetRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MassiveEditModuleWITable);
