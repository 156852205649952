/* eslint-disable array-callback-return */

import * as _ from "lodash";
import * as actions from "../../store/actions";

import {DragDropContainer, DropTarget} from "react-drag-drop-container";
import React, {Component} from "react";
import {
    canReopen,
    formatNumber,
    formatStatusLabel,
    getSku,
    isSameSku,
    isVisibleForUser,
    orderByPty,
    printPty,
} from "../../services/Api";

import Button from "../UI-components/Button";
import CustomIcon from "../UI-components/CustomIcon";
import CustomInput from "../UI-components/CustomInput";
import Loader from "../UI-components/Loader";
import PerformancePopup from "./PerformancePopup/PerformancePopup";
import Placeholder from "../UI-components/Placeholder";
import Popup from "../UI-components/Popup";
import PopupCopy from "../Copy-Composition/PopupCopy";
import ReactTooltip from "react-tooltip";
import Sku from "./Sku";
import SkuInfoPopup from "./SkuInfoPopup";
import {alertMessage} from "../../Mock/alertMessage";
import {connect} from "react-redux";
import {cloneDeep} from "lodash"

class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRemovedSkusOpen: false,
            moduleComponent: null,
            isEdited: false,
            isPublishPopupOpen: false,
            isModuleSavedPopup: false,
            isSkuPresentPopupOpen: false,
            isSkuInfoPopupOpen: false,
            isCopyPopupOpen: false,
            isSkuFromAnotherModPopupOpen: false,
            isEligibleVisible: false,
            skuClicked: null,
            isSkuDiscoPopupOpen: false,
            isPublishingModule: false,
            isModulePublishedPopup: false,
            isSavingNewDescription: false,
            isPopupModuleUnlockedOpen: false,
            phMh: 0,
            phEl: 0,
            // tB:03-2022
            isModuleCollapsed: true,
            placeholdersMH: [],
            placeholdersEL: [],
            placeholderScrollMH: false,
            placeholderScrollEL: false,
            restoreSku: false,
            skuDeleteRestore: null,
            description: {
                isEdited: false,
                value: null,
                editMode: false
            }
        };

        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount = () => {
        const moduleComponent = {...this.props.moduleComponent};
        const mhLength = moduleComponent.productDtoList.filter(
            (sku) => sku.mustHave && sku.moduleCompositionStatus !== "REMOVED"
        ).length;

        moduleComponent.length = moduleComponent.productDtoList.length;
        moduleComponent.elLength = moduleComponent.length - mhLength;

        if (!moduleComponent.status) {
            moduleComponent.status = "";
        }

        if (!moduleComponent.planningChannel) {
            moduleComponent.planningChannel = [];
        }

        if (moduleComponent.initialSize) {
            this.setState({
                phMh: moduleComponent.initialSize,
            });
        }
        delete moduleComponent.initialSize;

        this.setState({
            moduleComponent,
            description: {
                ...this.state.description,
                value: moduleComponent.moduleDescription
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.moduleComponent !== this.props.moduleComponent) {
            const moduleComponent = {...this.props.moduleComponent};
            const mhLength = moduleComponent.productDtoList.filter(
                (sku) => sku.mustHave && sku.moduleCompositionStatus !== "REMOVED"
            ).length;

            moduleComponent.length = moduleComponent.productDtoList.length;
            moduleComponent.elLength = moduleComponent.length - mhLength;

            this.setState({
                moduleComponent,
            });
        }

        if (
            prevState.moduleComponent !== this.state.moduleComponent ||
            prevProps.moduleComponent !== this.props.moduleComponent
        ) {
            this.setFinalModuleComponent();
        }

        if (
            prevProps.isSavingModule !== this.props.isSavingModule &&
            !this.props.isSavingModule
        ) {
            if (
                this.props.moduleSavedOrPublished ===
                this.props.moduleComponent.moduleName
            ) {
                if (this.props.errorSave !== null) {
                    this.setState({
                        isModuleSavedPopup: false,
                        isPopupModuleUnlockedOpen: true,
                        phMh: 0,
                        phEl: 0,
                    });
                } else {
                    const moduleComponent = {...this.props.moduleComponent};
                    const description = {
                        ...this.state.description,
                        editMode: false,
                        isEdited: false,
                        value: this.props.moduleComponent.moduleDescription
                    };
                    moduleComponent.isEdited = true;

                    this.setState({
                        isModuleSavedPopup: true,
                        isEdited: false,
                        phMh: 0,
                        phEl: 0,
                        description

                    });
                }
            }
        }

        if (
            prevProps.isPublishingModule !== this.props.isPublishingModule &&
            !this.props.isPublishingModule
        ) {
            if (
                this.props.moduleSavedOrPublished ===
                this.props.moduleComponent.moduleName
            ) {
                if (this.props.errorPublish !== null) {
                    this.setState({
                        isPublishingModule: false,
                        isPublishPopupOpen: false,
                        isPopupModuleUnlockedOpen: true,
                        phMh: 0,
                        phEl: 0,
                    });
                } else {
                    const moduleComponent = {...this.props.moduleComponent};
                    moduleComponent.isEdited = false;

                    this.setState({
                        isModulePublishedPopup: true,
                        isPublishingModule: false,
                        isPublishPopupOpen: false,
                        isEdited: false,
                        phMh: 0,
                        phEl: 0,
                    });
                }
            }
        }

        if (this.props.scrollHighlightWASku && this.props.highlightWASku) {
            //check if is eligible
            if (this.props.singleMacro) {
                this.state.moduleComponent.productDtoList.forEach((sku) => {
                    if (isSameSku(sku, this.props.highlightWASku)) {
                        if (
                            !sku.mustHave &&
                            sku.moduleCompositionStatus !== "REMOVED"
                        ) {
                            this.setState({
                                isEligibleVisible: true,
                            });
                        }
                    }
                });
            }

            //check if it's removed
            this.state.moduleComponent.productDtoList.forEach((sku) => {
                if (isSameSku(sku, this.props.highlightWASku)) {
                    if (sku.moduleCompositionStatus === "REMOVED") {
                        this.setState({
                            isRemovedSkusOpen: true,
                        });
                    }
                }
            });
        }

        //if(JSON.stringify(prevProps.moduleComponent) === JSON.stringify(this.props.moduleComponent)){
        if (prevState.moduleComponent
            && JSON.stringify(this.state.moduleComponent) !== JSON.stringify(prevState.moduleComponent)
            && _.isFunction(this.props.notifyChanges)) {

            const modifiedSku = _.differenceWith(prevState.moduleComponent.productDtoList, this.state.moduleComponent.productDtoList, _.isEmpty);
            const addedSku = _.differenceWith(this.state.moduleComponent.productDtoList, prevState.moduleComponent.productDtoList, _.isEmpty);
            const allMod = [...modifiedSku, ...addedSku];
            if (allMod.length > 0 && this.state.isEdited) {
                this.props.notifyChanges(this.state.moduleComponent.moduleName, allMod);
            }
        }
        //}


    }

    toggleRemovedSkus = () =>
        this.setState((prevState) => {
            return {isRemovedSkusOpen: !prevState.isRemovedSkusOpen};
        });

    onDragStart = (e, sku, where, moduleName) => {
        this.setState({skuDeleteRestore: sku,});
        if (!where) {
            this.props.dragSku(sku, moduleName);
        }
    };

    handleMinValueChange = (e, skuId) => {
        const minValue = e.target.value
        if (!isNaN(minValue)) {
            const moduleComponentCopy = cloneDeep(this.state.moduleComponent)
            moduleComponentCopy.productDtoList = moduleComponentCopy.productDtoList.map(sku => {
                if (sku.skuId === skuId) {
                    return ({
                        ...sku,
                        minValue: minValue
                    })
                }
                return sku
            })

            this.setState({
                moduleComponent: moduleComponentCopy
            })
        }
    }

    onDragOver = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    onDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let moduleComponent = _.cloneDeep(this.state.moduleComponent),
            droppedSku = {...this.props.droppedSku};

        //if the sku is dragged from another module --> handle it as active section
        if (
            !droppedSku.from &&
            moduleComponent.moduleName !== this.props.moduleFrom
        ) {
            droppedSku.from = "added";
        }

        let className = [];
        for (let i = 0; i < ev.currentTarget.children.length; i++) {
            className = [...className, ...ev.currentTarget.children[i].classList];
        }

        if (droppedSku) {
            if (
                droppedSku.moduleCompositionStatus === "REMOVED" &&
                moduleComponent.moduleName === this.props.moduleFrom
            ) {
                moduleComponent.productDtoList.forEach((p) => {
                    if (getSku(p) === getSku(droppedSku)) {
                        if (p.mustHave !== className.includes("must-have")) {
                            this.setState({
                                isEdited: true,
                                isAddFromRemovedOpen: true,
                            });
                        } else {
                            p.moduleCompositionStatus = "ADDED";
                            p.mustHave = false;

                            if (
                                moduleComponent.macroFamily &&
                                className.includes("must-have")
                            ) {
                                p.mustHave = true;
                            } else {
                                p.eligible = true;
                            }
                        }
                    }
                });

                moduleComponent.isEdited = true;

                this.setState({
                    moduleComponent,
                    isEdited: true,
                });
            } else if (
                droppedSku.moduleCompositionStatus !== "REMOVED" ||
                moduleComponent.moduleName !== this.props.moduleFrom
            ) {
                let checkIfPresent = moduleComponent.productDtoList.filter((p) => {
                    if (getSku(p) === getSku(droppedSku)) {
                        return true;
                    }
                });

                if (checkIfPresent.length === 0) {
                    droppedSku.moduleCompositionStatus = "ADDED";
                    droppedSku.mustHave = false;

                    if (
                        moduleComponent.macroFamily &&
                        className.includes("must-have")
                    ) {
                        droppedSku.mustHave = true;
                    }

                    moduleComponent.isEdited = true;

                    moduleComponent.productDtoList.push(droppedSku);
                    this.props.updateMacroList(moduleComponent);

                    this.setState({
                        moduleComponent,
                        isEdited: true,
                    });
                } else {
                    if (checkIfPresent[0].moduleCompositionStatus !== "REMOVED")
                        this.togglePopup("isSkuPresentPopupOpen");
                    else this.togglePopup("isAddFromRemovedOpen");
                }
            }
        }
        if (droppedSku.mustHave) {
            this.setState({phMh: this.state.phMh - 1,});
            this.removePlaceholder(this.state.placeholdersMH)
        } else {
            this.setState({phEl: this.state.phEl - 1,});
            this.removePlaceholder(this.state.placeholdersEL)
        }

        if (droppedSku && droppedSku.mustHave !== this.state.placeholderScrollMH) {
            this.setState({
                restoreSku: true,
                /*placeholderScrollMH:!this.state.placeholderScrollMH,
                placeholderScrollEL:!this.state.placeholderScrollEL,*/
            });
        }
    };

    addFromBin = () => {
        let moduleComponent = _.cloneDeep(this.state.moduleComponent);
        let droppedSku = this.props.droppedSku;

        moduleComponent.productDtoList.map((p) => {
            if (getSku(p) === getSku(droppedSku)) {
                p.moduleCompositionStatus = "ADDED";
            }
        });

        moduleComponent.isEdited = true;

        this.props.updateMacroList(moduleComponent);

        this.setState({
            moduleComponent,
            isEdited: true,
            isAddFromRemovedOpen: false,
            restoreSku: false,
        });
    };

    addFromBinSecond = () => {
        let moduleComponent = _.cloneDeep(this.state.moduleComponent);
        let droppedSku = this.props.droppedSku;

        moduleComponent.productDtoList.map((p) => {
            if (getSku(p) === getSku(droppedSku)) {
                p.moduleCompositionStatus = "ADDED";
                p.mustHave = p.mustHave === true ? false : true;
                p.eligible = !p.mustHave;
            }
        });

        moduleComponent.isEdited = true;
        this.props.updateMacroList(moduleComponent);

        this.setState({
            moduleComponent,
            isEdited: true,
            isAddFromRemovedOpen: false,
            restoreSku: false,
        });
    };

    addToBinHandler = (sku) => {
        let moduleComponent = _.cloneDeep(this.state.moduleComponent);
        let products = moduleComponent.productDtoList;

        for (let i = 0; i < products.length; i++) {
            if (getSku(products[i]) === getSku(sku)) {
                products[i].moduleCompositionStatus = "REMOVED";
                break;
            }
        }
        moduleComponent.isEdited = true;
        moduleComponent.productDtoList = products;
        this.setState({
            moduleComponent,
            isEdited: true,
        });

        this.props.updateMacroList(moduleComponent);
    };

    changeEligibleAndMH = (sku) => {
        let moduleComponent = _.cloneDeep(this.state.moduleComponent);
        for (var i = 0; i < moduleComponent.productDtoList.length; i++) {
            if (getSku(moduleComponent.productDtoList[i]) === getSku(sku)) {
                moduleComponent.productDtoList[i].mustHave = !sku.mustHave;
                // moduleComponent.productDtoList[i].eligible = !sku.eligible;
            }
        }

        moduleComponent.isEdited = true;

        this.setState({
            moduleComponent,
            isEdited: true,
        });
    };

    changeModuleLength = (operation, isEl = false) => {
        if (isEl) {
            this.setState({
                // isEdited: true,
                phEl: operation === "+" ? this.state.phEl + 1 : this.state.phEl - 1,
            });
        } else {
            this.setState({
                // isEdited: true,
                phMh: operation === "+" ? this.state.phMh + 1 : this.state.phMh - 1,
            });
        }
    };

    checkProductsModule = (
        moduleComponent,
        isBestSeller = false,
        isEl = false
    ) => {
        if (isBestSeller) {
            if (isEl) {
                let products = moduleComponent.productDtoList.filter((p) => {
                    return (
                        (p.moduleCompositionStatus === "ADDED" ||
                            p.moduleCompositionStatus === "NEW") &&
                        !p.mustHave
                    );
                });
                return moduleComponent.elLength > products.length;
            } else {
                let products = moduleComponent.productDtoList.filter((p) => {
                    return (
                        (p.moduleCompositionStatus === "ADDED" ||
                            p.moduleCompositionStatus === "NEW") &&
                        p.mustHave
                    );
                });
                return moduleComponent.length > products.length;
            }
        } else {
            let products = moduleComponent.productDtoList.filter((p) => {
                return (
                    p.moduleCompositionStatus === "ADDED" ||
                    p.moduleCompositionStatus === "NEW"
                );
            });
            return moduleComponent.length > products.length;
        }
    };

    togglePopup = (stateName) => {
        this.setState({
            // isEdited: true,
            isAddFromRemovedOpen: false,
            restoreSku: false,
        });
        this.setState((prevState) => {
            return {[stateName]: !prevState[stateName]};
        });
    }

    togglePopupRestore = () => {
        this.setState({
            isEdited: true,
            isAddFromRemovedOpen: false,
            restoreSku: false,
            isSkuPresentPopupOpen: false,
        });
    };

    closePopup = () => {
        this.props.resetSaveAndPublish();
        let moduleComponent = _.cloneDeep(this.state.moduleComponent);
        moduleComponent.isEdited = false;

        this.setState({
            isModuleSavedPopup: false,
            isModulePublishedPopup: false,
            isEdited: false,
        });
    };

    getInfoSku = (skuClicked) => {
        this.setState({skuClicked});
        this.props.getModulesByProducts(skuClicked, this.props.selectedFilters);
        this.togglePopup("isSkuInfoPopupOpen");
    };

    handleChange = (fieldName, opt) => {
        if (fieldName === 'moduleDescription') {
            const newValue = opt.target.value;
            const description = {...this.state.description}
            if (!this.state.description.isEdited) {
                description.oldValue = this.state.moduleComponent.moduleDescription
            }
            description.value = newValue;
            description.isEdited = this.state.moduleComponent.moduleDescription !== newValue;
            this.setState({
                description
            })
        }
    }

    toogleDescriptionEditMode = () => {
        this.setState({
            description: {
                ...this.state.description,
                editMode: true
            }
        })
    }

    //sl added
    getFilterList = (skuList) => {
        return skuList.filter(prod => {
            let ret = true;
            if (this.props.addedFilters) {
                ret = ret && (!this.props.addedFilters.release || !this.props.addedFilters.release.length || this.props.addedFilters.release.map(x => x.value).indexOf(prod.releaseDate) >= 0);
                ret = ret && (!this.props.addedFilters.modelName || !this.props.addedFilters.modelName.length || this.props.addedFilters.modelName.map(x => x.value).indexOf(prod.model) >= 0);

                const currentPrice = parseFloat(prod.srp.split(' ')[0]);
                if (this.props.addedFilters.minPrice && this.props.addedFilters.minPrice.length) {
                    const minPrice = parseFloat(this.props.addedFilters.minPrice);
                    ret = ret && currentPrice >= minPrice;
                }
                if (this.props.addedFilters.maxPrice && this.props.addedFilters.maxPrice.length) {
                    const maxPrice = parseFloat(this.props.addedFilters.maxPrice);
                    ret = ret && currentPrice <= maxPrice;
                }

            }
            return ret;

        })
    }
    //sl end

    printMustHave = (skuList, isDisabled) => {
        const filteredList = skuList.filter(
            (sku) =>
                sku.mustHave &&
                sku.moduleCompositionStatus !== "REMOVED" &&
                sku.moduleCompositionStatus === "ADDED" &&
                sku.from !== "added"
        );
        const filteredAdded = skuList.filter(
            (sku) =>
                sku.mustHave &&
                sku.moduleCompositionStatus !== "REMOVED" &&
                sku.moduleCompositionStatus === "ADDED" &&
                sku.from === "added"
        );


        const skuPrint = this.getFilterList([...filteredList, ...filteredAdded]).map((sku) => {

            return (
                <Sku
                    //CM added
                    className={sku.customClass}

                    key={sku.model + sku.color + sku.size}
                    isWorking
                    showNewInBadge={sku.newIn}
                    isHighlighted={
                        this.props.highlightWASku
                            ? isSameSku(sku, this.props.highlightWASku)
                            : false
                    }
                    scrollHighlightWASku={this.props.scrollHighlightWASku}
                    searchType="sku"
                    flagDisco={sku.flagDisco}
                    draggable={false}
                    openPopup={() => this.getInfoSku(sku)}
                    sku={sku}
                    isEditable={!isDisabled}
                    handleClickOnImg={() => {
                        this.props.highlightInActiveIfPresent(sku);
                    }}
                    handleClick={() => {
                        this.addToBinHandler(sku);
                    }}
                    handleBS={() => {
                        this.changeEligibleAndMH(sku);
                    }}
                    handleEligibleAndMH={() => {
                        this.changeEligibleAndMH(sku);
                    }}
                    isFaded={sku.isFaded}
                    hidePhoto={this.props.hidePhoto}
                    hideCatalogue={this.props.hideCatalogue}
                />
            );
        });
        if (skuPrint.length > 0) return skuPrint;
    };

    printEligible = (eligibleList, isDisabled) => {

        const filteredList = eligibleList.filter(
            (sku) =>
                sku.moduleCompositionStatus !== "REMOVED" &&
                sku.moduleCompositionStatus === "ADDED" &&
                sku.from !== "added"
        );

        const filteredAdded = eligibleList.filter(
            (sku) =>
                sku.moduleCompositionStatus !== "REMOVED" &&
                sku.moduleCompositionStatus === "ADDED" &&
                sku.from === "added"
        );

        const skuPrint = this.getFilterList([...filteredList, ...filteredAdded]).map((sku) => {
            return (
                <Sku
                    //CM added
                    className={sku.customClass}

                    key={sku.model + sku.color + sku.size}
                    isWorking
                    showNewInBadge={sku.newIn}
                    isHighlighted={
                        this.props.highlightWASku
                            ? isSameSku(sku, this.props.highlightWASku)
                            : false
                    }
                    scrollHighlightWASku={this.props.scrollHighlightWASku}
                    flagDisco={sku.flagDisco}
                    draggable={false}
                    sku={sku}
                    openPopup={() => this.getInfoSku(sku)}
                    searchType="sku"
                    isEditable={!isDisabled}
                    handleClickOnImg={() => {
                        this.props.highlightInActiveIfPresent(sku);
                    }}
                    handleClick={() => {
                        this.addToBinHandler(sku);
                    }}
                    handleEligibleAndMH={() => {
                        this.changeEligibleAndMH(sku);
                    }}
                    handleBS={() => {
                        this.changeEligibleAndMH(sku);
                    }}
                    isFaded={sku.isFaded}
                />
            );
        });

        if (skuPrint.length > 0) return skuPrint;
        if (skuPrint.length === 0 && this.state.placeholdersEL.length === 0) {
            return <div className="no-eligibles">No eligibles</div>;
        }
    };

    printRemovedSku = (skuList, isDisabled) => {
        let countRemovedSku = 0;

        let filterRemovedSku = skuList.filter((sku) => sku.previousVersion);
        filterRemovedSku = this.getFilterList(filterRemovedSku);
        let printSku = filterRemovedSku.map((sku) => {
            if (sku.moduleCompositionStatus === "REMOVED") {
                countRemovedSku++;
                return (
                    <DragDropContainer
                        targetKey="drag-drop-module"
                        key={sku.model + sku.color + sku.size}
                        onDragStart={(e) =>
                            this.onDragStart(
                                e,
                                sku,
                                null,
                                this.state.moduleComponent.moduleName
                            )
                        }
                    >
                        <Sku
                            //CM added
                            className={sku.customClass}

                            key={sku.model + sku.color + sku.size}
                            isWorking
                            moduleCompositionStatus={sku.moduleCompositionStatus}
                            showNewInBadge={sku.newIn}
                            isHighlighted={
                                this.props.highlightWASku
                                    ? isSameSku(sku, this.props.highlightWASku) &&
                                    this.state.isRemovedSkusOpen
                                    : false
                            }
                            scrollHighlightWASku={
                                this.props.scrollHighlightWASku &&
                                this.state.isRemovedSkusOpen
                            }
                            draggable
                            searchType="sku"
                            // isEditable={false}
                            isEditable={!isDisabled}
                            active={sku.newness_to_add}
                            openPopup={() => this.getInfoSku(sku)}
                            sku={sku}
                            handleClickOnImg={() =>
                                this.props.highlightInActiveIfPresent(sku)
                            }
                            handleClick={() => this.addToBinHandler(sku)}
                            isFaded={sku.isFaded}
                            handleEligibleAndMH={() => this.changeEligibleAndMH(sku)}
                        />
                    </DragDropContainer>
                );
            }
        });

        if (countRemovedSku === 0)
            printSku = <div className="empty-div"> There aren't removed sku</div>;

        return {
            printSku,
            countRemovedSku,
        };
    };

    printPlaceholder = (isMh = false) => {
        const numberOfPlaceholder = isMh ? this.state.phMh : this.state.phEl;
        const placeholdersMH = [];
        const placeholdersEL = [];
        for (let i = 0; i <= numberOfPlaceholder; i++) {
            if (isMh) {
                placeholdersMH.push('')
                this.setState({placeholdersMH})
            } else {
                placeholdersEL.push('')
                this.setState({placeholdersEL})
            }
        }

    };
    removePlaceholder = (placeholders) => {
        placeholders.pop()
        this.setState({placeholders})
    }

    checkBeforeSaving = () => {
        if (this.state.description.isEdited) {
            this.togglePopup("isSavingNewDescription");
        } else this.saveModule(false);
    }


    saveModule = (changeDescription) => {
        const moduleToSave = _.cloneDeep(this.state.moduleComponent);
        if (changeDescription && this.state.description.isEdited) {
            moduleToSave.moduleDescription = this.state.description.value;
        }
        if (!this.props.isSavingModule) {
            if (!moduleToSave.userLock) {
                moduleToSave.userLock = this.props.username;
            }

            this.setState({
                isSavingNewDescription: false
            })

            this.props.saveModule(
                moduleToSave,
                false,
                this.props.moduleComponent.macroFamily,
                !this.state.isEdited && this.state.description.isEdited
            );
        }
    };

    publishModule = () => {
        if (!this.state.isPublishingModule) {
            let moduleP = _.cloneDeep(this.state.moduleComponent),
                hasSkuDisc = false;

            if (hasSkuDisc) {
                this.setState({
                    isSkuDiscoPopupOpen: true,
                    isPublishPopupOpen: false,
                });
            } else {
                this.props.publishModule(
                    moduleP,
                    this.props.moduleComponent.macroFamily
                );
                this.setState({
                    isPublishingModule: true,
                    isPublishPopupOpen: false,
                });
            }
        }
    };

    setFinalModuleComponent() {
        this.props.onModuleChange(this.state.moduleComponent);
    }

    isScrollActive(e) {
        return e.offsetHeight < e.scrollHeight;
    }

    setModuleHeight(height) {
        this.setState({viewportHeigth: height})
    }

    handleHeightChange(e) {
        this.setState({viewportHeigth: e})
    }

    checkRestoreSku = () => {
        let moduleComponent = _.cloneDeep(this.state.moduleComponent)
        let restoreSku = moduleComponent.productDtoList.find(c => c.skuId === this.props.droppedSku.skuId);
        return (restoreSku.mustHave && this.state.placeholderScrollMH) ||
        (restoreSku.eligible && this.state.placeholderScrollEL) ? true : false;
    }


    render() {
        let {
            moduleComponent,
            isEligibleVisible,
            isRemovedSkusOpen,
            isModuleSavedPopup,
            isModulePublishedPopup,
            isPopupModuleUnlockedOpen,
            description
        } = this.state;
        let {isReleaseClosed} = this.props;
        // check editable element of the page should be disabled
        const disableAll =
            this.props.moduleComponent.status === "SUBMIT_IN_PROGRESS" ||
            (this.props.moduleComponent.status === "SUBMITTED" &&
                this.props.auth &&
                this.props.auth.grants &&
                !canReopen(
                    this.props.auth.grants,
                    this.props.moduleComponent.brand,
                    this.props.moduleComponent.starZones
                )) ||
            isReleaseClosed ||
            this.props.isDisabled ||
            // verify if the user has created the module
            (this.props.moduleComponent.locked &&
                this.props.moduleComponent.userLock.toUpperCase() !==
                this.props.username.toUpperCase());

        // disable edit
        const isDisabled =
            disableAll ||
            // verify if user has grants to edit
            (this.props.auth.grants &&
                !isVisibleForUser(
                    this.props.auth.grants,
                    false,
                    this.props.moduleComponent.brand,
                    this.props.moduleComponent.starZones
                ));

        // disable submit
        const isSubmitDisabled = () => {
            return (
                disableAll ||
                (this.props.auth.grants &&
                    !isVisibleForUser(
                        this.props.auth.grants,
                        true,
                        this.props.moduleComponent.brand,
                        this.props.moduleComponent.starZones
                    ))
            );
        };

        let removedSkuObj = moduleComponent
            ? this.printRemovedSku(moduleComponent.productDtoList, isDisabled)
            : {printSku: "", countRemovedSku: 0};
        if (this.state.moduleComponent)
            moduleComponent = orderByPty({...this.state.moduleComponent});

        const eligibleFilter = (products) => {
            return products.filter(
                (sku) =>
                    !sku.mustHave &&
                    sku.mustHave === false &&
                    sku.moduleCompositionStatus !== "REMOVED" &&
                    sku.moduleCompositionStatus === "ADDED"
            );
        };

        // const eligibleList = moduleComponent !== null && eligibleFilter(moduleComponent.products);
        //27-10-2021: modificato passaggio parametro da products a productDtoList per risolvere anomalia ordinamento realTime per Sell Out, vecchio codice sulla riga precedente a questo commento
        const eligibleList =
            moduleComponent !== null &&
            eligibleFilter(moduleComponent.productDtoList);

        const musthaveFilter = (products) => {
            return products.filter(
                (sku) => sku.mustHave && sku.moduleCompositionStatus !== "REMOVED"
            );
        };
        const musthaveList =
            moduleComponent !== null && musthaveFilter(moduleComponent.products);

        const disableCta =
            this.props.auth &&
            this.props.auth.grants &&
            this.props.auth.grants.some((grant) => {
                if (
                    (/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
                    && grant.types.includes("edit")
                ) {
                    return false;
                } else {
                    return true;
                }
            });

        /*
        const computeDiscoStats = (filterFun) => {
           if(this.props.moduleComponent?.productDtoList){
              const totDisco = this.props.moduleComponent.productDtoList.filter(filterFun).length
              const totSku = this.props.moduleComponent.productDtoList.length

              return ({
                 tot: totDisco,
                 perc: `${(totDisco / totSku).toFixed(3) * 100}%`
              })
           }
           return ({
              tot: '-',
              perc: '-'
           })
        }
        */

        const inOpenMonth = this.props.moduleComponent.inOpenMonth;
        const refreshIndex = (this.props.moduleComponent.refreshIndex || this.props.moduleComponent.refreshIndex === 0) ?
            `${this.props.moduleComponent.refreshIndex}%` : '-';
        const refreshIndexMH = (this.props.moduleComponent.refreshIndexMH || this.props.moduleComponent.refreshIndexMH === 0) ?
            `${this.props.moduleComponent.refreshIndexMH}%` : '-';
        const refreshIndexEL = (this.props.moduleComponent.refreshIndexEL || this.props.moduleComponent.refreshIndexEL === 0) ?
            `${this.props.moduleComponent.refreshIndexEL}%` : '-';
        //const totDiscoMh = computeDiscoStats((sku) => sku.mustHave)
        //const totDiscoEl = computeDiscoStats((sku) => sku.eligible)
        //const totDiscoMhEl = computeDiscoStats((sku) => sku.eligible || sku.mustHave)

        const previousMustHave = this.props.moduleComponent.previousMustHave;
        const previousEligible = this.props.moduleComponent.previousEligible;

        const prevMonthTooltipMH = (previousMustHave || previousMustHave === 0) ? `${previousMustHave} MH` : 'NO PREVIOUS MH';
        const prevMonthTooltipEL = (previousEligible || previousEligible === 0) ? `${previousEligible} EL` : 'NO PREVIOUS EL';

        const prevMonthTooltip = (prevMonthTooltipMH === 'NO PREVIOUS MH' && prevMonthTooltipEL === 'NO PREVIOUS EL') ?
            'NO PREVIOUS MONTH'
            :
            `Previous Month: ${prevMonthTooltipMH} - ${prevMonthTooltipEL}`

        const diffPrevMh = previousMustHave ? musthaveList.length !== previousMustHave : false;
        const diffPrevEl = previousEligible ? eligibleList.length !== previousEligible : false;

        const diffPrevMonthLength = diffPrevMh || diffPrevEl;
        const isModuleCollapsed = this.state.isModuleCollapsed;
        const placeholdersMH = this.state.placeholdersMH;
        const placeholdersEL = this.state.placeholdersEL;

        return (
            <React.Fragment>
                {moduleComponent !== null ? (
                    <div
                        id={"module-" + moduleComponent.moduleName}
                        onDragStart={(ev) => {
                            if (moduleComponent) {
                                const elSource = document.getElementById("module-" + moduleComponent.moduleName);
                                const ghost = elSource.cloneNode(true);
                                ghost.style.position = 'absolute';
                                ghost.style.top = '-100000px';
                                ghost.id = "module-" + moduleComponent.moduleName + '_ghost';
                                elSource.parentElement.appendChild(ghost);
                                ev.dataTransfer.setDragImage(ghost, 0, 0);
                            }

                        }}
                        onDragEnd={() => {
                            document.getElementById("module-" + moduleComponent.moduleName + '_ghost')?.remove()
                        }}

                        className={
                            "modulo-outline " +
                            (isEligibleVisible ? "must-have-open " : " ") +
                            (isModuleCollapsed
                                ? "collapsed-module"
                                : "closed-module")
                        }
                    >
                        {/* tB:03-2022 */}
                        {!isDisabled && isModuleCollapsed && (
                            <div className="btn-container-module">
                                <span style={{margin: "0 0.25rem"}}>MH</span>
                                <Button
                                    btnClass="btn-plus"
                                    disabled={isDisabled}
                                    handleClick={() => {
                                        this.changeModuleLength("+");
                                        this.printPlaceholder(true);
                                        this.setState({placeholderScrollMH: true, placeholderScrollEL: false,})
                                    }}
                                >
                                    +
                                </Button>
                                <Button
                                    btnClass="btn-minus"
                                    disabled={isDisabled || !this.state.phMh}
                                    handleClick={() => {
                                        this.changeModuleLength("-");
                                        this.removePlaceholder(this.state.placeholdersMH)
                                    }}
                                >
                                    -
                                </Button>
                            </div>
                        )}
                        {/* tB:03-2022 */}
                        {isModuleCollapsed && isEligibleVisible && !isDisabled && (
                            <div
                                className="btn-container-module"
                                style={{top: "50%"}}
                            >
                                <span style={{margin: "0 0.6rem"}}>EL</span>
                                <Button
                                    btnClass="btn-plus"
                                    disabled={isDisabled}
                                    handleClick={() => {
                                        this.changeModuleLength("+", true);
                                        this.printPlaceholder(false)
                                        this.setState({placeholderScrollEL: true, placeholderScrollMH: false,})
                                    }}
                                >
                                    +
                                </Button>
                                <Button
                                    btnClass="btn-minus"
                                    disabled={isDisabled || !this.state.phEl}
                                    handleClick={() => {
                                        this.changeModuleLength("-", true);
                                        this.removePlaceholder(this.state.placeholdersEL)
                                    }}
                                >
                                    -
                                </Button>
                            </div>
                        )}
                        {isModuleCollapsed && <div className={"bestseller-container "}>
                            {/* tB:03-2022 */}
                            <div
                                className="bestseller-container__button-info--container"

                            >
                                <Button
                                    btnClass="button btn-label btn-right bestseller-container__button-info"
                                    handleClick={() => {
                                        this.togglePopup("isPerformancePopupOpen");
                                    }}
                                >
                                    <span className="bestseller-container__info--icon icon-info"/>
                                </Button>
                            </div>

                            <h4>Must Have</h4>
                            <div className="row no-gutters bestseller">
                                {this.printMustHave(
                                    moduleComponent.productDtoList,
                                    isDisabled
                                )}
                                {placeholdersMH && placeholdersMH.map((ph, i) => (
                                    <DropTarget
                                        targetKey="drag-drop-module"
                                        key={"placeholder" + i}
                                        onDragOver={this.onDragOver}
                                        onHit={this.onDrop}
                                    >
                                        <Placeholder
                                            idModule={"module-" + moduleComponent.moduleName + i}
                                            isBestSeller={true}
                                            isEl={false}
                                            idDragg="draggArea"
                                            placeholderScroll={this.state.placeholderScrollMH}

                                        />
                                    </DropTarget>
                                ))}
                            </div>
                            <div
                                className="title-container"
                                onClick={() => this.togglePopup("isEligibleVisible")}
                            >
                                <h4 className="eligible">Eligible</h4>
                                <CustomIcon
                                    iconClass={
                                        "icon-arrow1" +
                                        (isEligibleVisible ? " up" : " down")
                                    }
                                />
                            </div>

                            {isEligibleVisible && (
                                <div className="row no-gutters eligible-container">
                                    {this.printEligible(eligibleList, isDisabled)}
                                    {placeholdersEL.map((ph, i) => (
                                        <DropTarget
                                            targetKey="drag-drop-module"
                                            key={"placeholder" + i}
                                            onDragOver={this.onDragOver}
                                            onHit={this.onDrop}
                                        >
                                            <Placeholder
                                                idModule={"module-" + moduleComponent.moduleName + i}
                                                isBestSeller={true}
                                                isEl={true}
                                                idDragg="draggArea"
                                                placeholderScroll={this.state.placeholderScrollEL}

                                            />
                                        </DropTarget>
                                    ))}
                                </div>
                            )}
                        </div>}
                        <div
                            className={
                                "row no-gutters removed-sku " +
                                (isRemovedSkusOpen && isModuleCollapsed ? "show" : "")
                            }
                        >
                            {removedSkuObj.printSku}
                        </div>
                        {/* tB:03-2022 */}
                        <div className="footer-module">
                            <div className="info-modulo">
                                <div className="title-modulo">
                                    <p>
                                        {moduleComponent.moduleLabel} -{' '}
                                        {musthaveList.length} <span> MH </span> -{' '}
                                        {eligibleList.length} <span> EL </span>

                                        <span className="bold">
                                 {" "}
                                            {moduleComponent.door
                                                ? formatNumber(moduleComponent.door)
                                                : 0}{" "}
                                            D{" "}
                              </span>
                                        -{" "}
                                        <span>
                                 {moduleComponent.pty
                                     ? formatNumber(printPty(moduleComponent.pty))
                                     : 0}{" "}
                                            PTY{" "}
                              </span>
                                    </p>
                                    <span
                                        className="tooltip-prevMonth"
                                    >
                              {prevMonthTooltip}
                           </span>
                                    <ReactTooltip
                                        className="disco-remove-table__tooltip--style"
                                        effect="solid"
                                    />
                                </div>

                                {this.props.hideRemoved ? null : (
                                    <CustomIcon
                                        iconClass={
                                            "icon-cestino" +
                                            (isRemovedSkusOpen ? " open" : "")
                                        }
                                        iconContClass={
                                            isModuleCollapsed
                                                ? ""
                                                : " disable-action"
                                        }
                                        removedLength={
                                            removedSkuObj.countRemovedSku > 0
                                                ? removedSkuObj.countRemovedSku
                                                : 0
                                        }
                                        handleClick={this.toggleRemovedSkus}
                                    />
                                )}
                                {moduleComponent.status && (
                                    <div className="mc-status ml-1">
                                        {formatStatusLabel(moduleComponent.status)}
                                    </div>
                                )}
                            </div>
                            <div>
                                {
                                    description.editMode || moduleComponent.moduleDescription === null || !moduleComponent.moduleDescription ?
                                        <CustomInput
                                            handleChange={(e) =>
                                                this.handleChange("moduleDescription", e)
                                            }
                                            placeholder="Module Description"
                                            maxLength="100"
                                            value={description.value}
                                        ></CustomInput> :
                                        <p style={{
                                            padding: "15px 0",
                                            color: "grey",
                                            fontStyle: "italic",
                                            cursor: "text"
                                        }}
                                           onClick={() => this.toogleDescriptionEditMode()}>{moduleComponent.moduleDescription}</p>
                                }
                            </div>
                            <div className={"prevMonthDetail-container "}>

                                <div>
                                    {inOpenMonth !== undefined &&
                                        <p className={inOpenMonth ? 'open-month' : ''}>
                                            {(inOpenMonth ? 'open' : 'closed') + ' month'}
                                        </p>}
                                </div>
                                <p>Ref MH: <span>{refreshIndexMH}</span></p>
                                <p>Ref EL: <span>{refreshIndexEL}</span></p>
                                <p>Ref MH+EL: <span>{refreshIndex}</span></p>
                                {
                                    /*
                                    <>
                                       <p>Tot Disco MH: <span>{totDiscoMh.tot}</span></p>
                                       <p>Tot Disco EL: <span>{totDiscoEl.tot}</span></p>
                                       <p>Tot Disco MH+EL: <span>{totDiscoMhEl.tot}</span></p>
                                       <p>Tot Disco MH %: <span>{totDiscoMh.perc}</span></p>
                                       <p>Tot Disco EL %: <span>{totDiscoEl.perc}</span></p>
                                       <p>Tot Disco MH+EL %: <span>{totDiscoMhEl.perc}</span></p>
                                    </>
                                    */
                                }
                            </div>
                            <div className={"btn-save-container "}>
                                {!isSubmitDisabled() && (
                                    <Button
                                        btnClass="btn-label btn-right"
                                        disabled={
                                            moduleComponent.status !== "WIP" ||
                                            this.props.isSavingModule
                                        }
                                        handleClick={() => {
                                            this.togglePopup("isPublishPopupOpen");
                                        }}
                                    >
                                        {this.state.isPublishingModule
                                            ? "Publishing..."
                                            : "Publish"}
                                    </Button>
                                )}
                                {(!isDisabled || this.state.description.isEdited) && (
                                    <Button
                                        btnClass="btn-label btn-left"
                                        disabled={
                                            (!this.state.isEdited &&
                                                !this.state.description.isEdited) ||
                                            this.state.isPublishingModule ||
                                            this.props.isPublishingModule
                                        }
                                        handleClick={this.checkBeforeSaving}
                                    >
                                        {this.props.isSavingModule ? "Saving..." : "Save"}
                                    </Button>
                                )}
                                <Button
                                    disabled={disableCta}
                                    btnClass="btn-label btn-left btn-copy"
                                    handleClick={() =>
                                        this.togglePopup("isCopyPopupOpen")
                                    }
                                >
                                    {"COPY"}
                                </Button>
                                {/* tB:03-2022 */}
                                <Button
                                    btnClass="btn-label btn-right"
                                    handleClick={() => {
                                        this.setState({
                                            isModuleCollapsed:
                                                !this.state.isModuleCollapsed,
                                            placeholderScrollMH: false,
                                            placeholderScrollEL: false,
                                        });
                                    }}
                                >
                                    {isModuleCollapsed ? (
                                        <span className="icon-opened-module icon-arrow1"/>
                                    ) : (
                                        <span className="icon-closed-module icon-arrow1"/>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader/>
                )
                }

                {
                    this.state.isPerformancePopupOpen && (
                        <PerformancePopup
                            showPopup={() => this.togglePopup("isPerformancePopupOpen")}
                            popupClass="popup-small"
                            selectedModule={moduleComponent}
                        />
                    )
                }

                {
                    this.state.isPublishPopupOpen && (
                        <Popup
                            close={() => this.togglePopup("isPublishPopupOpen")}
                            title="Publish module"
                            isDoubleButton
                            btnName2="No"
                            btnName="Yes"
                            popupClass="popup-small"
                            handleClick={this.publishModule}
                        >
                            Are you sure you want to publish the changes on Rpas??
                            {diffPrevMonthLength &&
                                <div className="popoup-caution">
                                    <h1>CAUTION!</h1>
                                    <p>{alertMessage.alertSinglePublish}</p>
                                </div>
                            }
                        </Popup>
                    )
                }
                {
                    this.state.isSavingNewDescription && (
                        <Popup
                            close={() => this.togglePopup("isSavingNewDescription")}
                            title="Save module"
                            isDoubleButton
                            btnName2="No"
                            btnName="Yes"
                            popupClass="popup-small"
                            handleClick={() => this.saveModule(true)}
                            handleClickSecondary={() => this.saveModule(false)}
                        >
                            Are you sure you want also to change the module description?
                        </Popup>
                    )
                }
                {
                    this.state.isSkuDiscoPopupOpen && (
                        <Popup
                            close={() => this.togglePopup("isSkuDiscoPopupOpen")}
                            btnName="Close"
                            popupClass="popup-small"
                            handleClick={() => this.togglePopup("isSkuDiscoPopupOpen")}
                        >
                            You can’t publish the module because there is a discontinuated
                            sku.
                        </Popup>
                    )
                }
                {
                    isModuleSavedPopup && (
                        <Popup
                            close={this.closePopup}
                            title="Module correctly saved"
                            popupClass="popup-small"
                        />
                    )
                }
                {
                    isModulePublishedPopup && (
                        <Popup
                            close={this.closePopup}
                            title="The submission process has been started"
                            popupClass="popup-small"
                        />
                    )
                }
                {
                    isPopupModuleUnlockedOpen && (
                        <Popup
                            close={() => this.togglePopup("isPopupModuleUnlockedOpen")}
                            title=""
                            popupClass="popup-small"
                        >
                            {this.props.errorSave && this.props.errorSave.message && (
                                <p className="like-title">
                                    {this.props.errorSave.message}
                                </p>
                            )}
                        </Popup>
                    )
                }

                {
                    (this.state.isAddFromRemovedOpen && !this.checkRestoreSku() && !this.state.isSkuPresentPopupOpen) && (
                        <Popup
                            close={() => this.togglePopupRestore()}
                            title="WARNING"
                            btnName2="Yes"
                            btnName="No"
                            isFlex
                            isDoubleButton
                            handleClick={this.addFromBin}
                            handleClickSecondary={this.addFromBinSecond}
                        >
                            The status selected is different from the SKU's original status.<br/>
                            Do you confirm the change?
                        </Popup>
                    )
                }

                {
                    (this.state.isAddFromRemovedOpen && this.checkRestoreSku() && !this.state.isSkuPresentPopupOpen) && (
                        <Popup
                            close={() => this.togglePopupRestore()}
                            title="WARNING"
                            btnName="Yes"
                            isFlex
                            handleClick={this.addFromBin}
                        >
                            The sku is already present in the removed ones, do you want to
                            add it back?
                        </Popup>
                    )
                }

                {
                    !this.state.restoreSku && this.state.isSkuPresentPopupOpen && (
                        <Popup
                            popupClass="popup-small"
                            close={() => this.togglePopup("isSkuPresentPopupOpen")}
                            title="Error!"
                            isFlex
                            btnName="Close"
                            handleClick={() => this.togglePopup("isSkuPresentPopupOpen")}
                        >
                            The sku is already present
                        </Popup>
                    )
                }
                {
                    this.state.isSkuFromAnotherModPopupOpen && (
                        <Popup
                            popupClass="popup-small"
                            close={() => this.togglePopup("isSkuFromAnotherModPopupOpen")}
                            title="Error!"
                            isFlex
                            btnName="Close"
                            handleClick={() => {
                                this.togglePopup("isSkuFromAnotherModPopupOpen");
                            }}
                        >
                            The starting module is different from the end module.
                        </Popup>
                    )
                }
                {
                    this.state.isSkuInfoPopupOpen && (
                        <SkuInfoPopup
                            isWorking
                            skuClicked={this.state.skuClicked}
                            productModuleList={this.props.productModuleList}
                            moduleDetails={this.state.moduleComponent}
                            handleMinValueChange={this.handleMinValueChange}
                            close={() => {
                                this.togglePopup("isSkuInfoPopupOpen");
                                this.props.resetGetModulesByProduct();
                            }}
                        />
                    )
                }
                {
                    this.state.isCopyPopupOpen && (
                        <PopupCopy
                            auth={this.props.auth}
                            type={"module"}
                            data={moduleComponent}
                            popupClass="popup-copy"
                            close={() => this.togglePopup("isCopyPopupOpen")}
                            handleClick={() => this.togglePopup("isCopyPopupOpen")}
                            btnName={["Cancel", "Save", "Save & Publish"]}
                        />
                    )
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        droppedSku: state.module.draggedSku,
        moduleFrom: state.module.moduleFrom,
        isReleaseClosed: state.release.isReleaseClosed,
        isModuleSaved: state.module.isModuleSaved,
        isModulePublished: state.module.isModulePublished,
        moduleSavedOrPublished: state.module.moduleSavedOrPublished,
        selectedFilters: state.filter.selectedFilters,
        productModuleList: state.module.productModuleList,
        username: state.authentication.user.user_name,
        highlightWASku: state.module.highlightWASku,
        scrollHighlightWASku: state.module.scrollHighlightWASku,
        isSavingModule: state.module.isSavingModule,
        isPublishingModule: state.module.isPublishingModule,
        errorSave: state.module.errorSave,
        errorPublish: state.module.errorPublish,
        auth: state.authentication,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dragSku: (sku, moduleName) => dispatch(actions.dragSku(sku, moduleName)),
        saveModule: (mod, isCreationByEditor, familyName, onlyDescription) =>
            dispatch(actions.saveModule(mod, isCreationByEditor, familyName, onlyDescription)),
        publishModule: (mod, macrofamilyName) =>
            dispatch(actions.publishModule(mod, macrofamilyName)),
        resetSaveAndPublish: () => dispatch(actions.resetSaveAndPublish()),
        getModulesByProducts: (sku, filters) =>
            dispatch(actions.getModulesByProducts(sku, filters)),
        resetGetModulesByProduct: () =>
            dispatch(actions.resetGetModulesByProduct()),
        highlightInActive: (sku) => dispatch(actions.highlightInActive(sku)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Module);
