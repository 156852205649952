import React, { useState } from "react";
import CustomSelect from "../UI-components/CustomSelect";
import Button from "../UI-components/Button";
import star from "../../img/Vector.png";
import { Link } from "react-router-dom";
import ConfermationLiaPopup from "../Cluster-Composition/Cluster-Lia/ConfermationLiaPopup";
import PopupCopy from "../Copy-Composition/PopupCopy";

const Header = ({
   auth,
   data,
   handlePublishClick,
   handleSaveClick,
   handleSearchFirst,
   handleSearchSecond,
   isClusterChanged,
   isDescriptionChanged,
   isDisabled,
   isLiaListEdited,
   isClassificationEdited,
   isLoadingPublish,
   isLoadingSave,
   isModuleComp,
   isSubmitDisabled,
   isWip,
   optionsFirst,
   optionsSecond,
   type,
   selectedFilters,
}) => {
   const [isCopyPopupOpen, setIsCopyPopupOpen] = useState(false);

   const disableCta =
      auth &&
      auth.grants &&
      auth?.grants?.every((grant) => {
         if (
             /admin/i.test(grant.role) || (/CREATOR/i.test(grant.role)
             && (grant.types.includes("edit") || grant.types.includes("submit")))
         ) {
            return false;
         } else {
            return true;
         }
      });

   const typeFixed = type === undefined ? "" : type;

   const label =
      (type === "manageUser" || type === "manageRelease") && type !== undefined
         ? null
         : "Edit " + typeFixed;

   return (
      <>
         <header className={"header-page " + typeFixed}>
            <div className="header-content">
               {/* {props.isNewModule  */}
               <div className="icon-home">
                  <Link to="/">
                     <img
                        src={star}
                        alt="MyStars Assortment"
                        className="logo-star"
                     />
                  </Link>
               </div>
               {/* : <div className="icon-home"><Link to={{pathname:"/", search:"?unlock=true"}}><img src={star} alt="MyStars Assortment" className="logo-star"/></Link></div> */}
               {/* } */}
               <div className="brand-logo">{label}</div>
               {

               }
               {type === "manageRelease" || type === "manageUser" || type === "cluster" ? null : (
                  <CustomSelect
                     placeholder={"Search " + (type ? type : "")}
                     selectClass="select-header"
                     options={optionsFirst}
                     handleChange={handleSearchFirst}
                  />
               )}
               {isModuleComp && (
                  <CustomSelect
                     placeholder={"Search family"}
                     selectClass="select-header"
                     options={optionsSecond}
                     handleChange={handleSearchSecond}
                  />
               )}
                {selectedFilters && (type === "module" || type === "cluster") && (
                  <div className="text-month-container">
                     <p
                        style={{
                           textTransform: "capitalize",
                           whiteSpace: "nowrap",
                        }}
                     >
                        You searched:&nbsp;
                        <span className="bold" style={{ paddingLeft: "2px" }}>
                           {selectedFilters && selectedFilters.months?.label}
                           &nbsp;
                        </span>
                     </p>
                  </div>
               )}
            </div>

            <div className="btn-container">
               {type === "cluster" ? (
                  <Button
                     disabled={disableCta}
                     btnClass="btn-medium"
                     handleClick={() => setIsCopyPopupOpen(true)}
                  >
                     {"COPY"}
                  </Button>
               ) : null}
               {type === "cluster" && (!isDisabled || isDescriptionChanged) ? (
                  isLoadingSave ? (
                     <Button btnClass="btn-medium" disabled>
                        Saving...
                     </Button>
                  ) : isLoadingPublish ? (
                     <Button
                        btnClass="btn-medium"
                        disabled
                        handleClick={handleSaveClick}
                     >
                        Save
                     </Button>
                  ) : (
                     <Button
                        btnClass="btn-medium"
                        disabled={!isClusterChanged && !isLiaListEdited && !isClassificationEdited}
                        handleClick={handleSaveClick}
                     >
                        Save
                     </Button>
                  )
               ) : null}
               {type === "cluster" && !isSubmitDisabled ? (
                  isLoadingPublish ? (
                     <Button btnClass="btn-medium" disabled>
                        Publishing...
                     </Button>
                  ) : isLoadingSave ? (
                     <ConfermationLiaPopup>Publish</ConfermationLiaPopup>
                  ) : (
                     <Button
                        btnClass="btn-medium"
                        disabled={!(isWip || isClusterChanged)}
                        handleClick={handlePublishClick}
                     >
                        Publish
                     </Button>
                  )
               ) : null}
            </div>
         </header>

         {type === "cluster" && isCopyPopupOpen ? (
            <PopupCopy
               auth={auth}
               type={"cluster"}
               data={data}
               popupClass="popup-copy"
               close={() => setIsCopyPopupOpen(false)}
               handleClick={() => setIsCopyPopupOpen(false)}
               btnName={["Cancel", "Save", "Save & Publish"]}
            />
         ) : null}
      </>
   );
};

/*
const mapStateToProps = (state) => {
   return {
      selectedFilters: state.filter.selectedFilters,
   };
};
*/
/*
const mapDispatchToProps = (dispatch) => {
   return {};
};
*/
export default Header;
