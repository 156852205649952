/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import * as actions from "../../store/actions";
import * as urlList from "../../config";

import {FRONT_OFFICE_URL, SKU_DISCO_ALERT} from "../../config";
import {
   isAdmin,
   isSubmitUser,
   canImportData
} from "../../services/Api";

import AddClientClassificationPopup from "./Popup/AddClientClassificationPopup";
import Button from "../UI-components/Button";
import CustomSelect from "../UI-components/CustomSelect";
import LeftBar from "../UI-components/LeftBar";
import LinkedButton from "../UI-components/LinkedButton";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { getCookie } from "../../services/cookie";

class Filters extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         sunCheck: false, 
         viewCheck: false,
         error: false,
         error_message: null,
         selectedCollectionOptions: [],
         checked: false,
         discoLength: 0,
         removedLength: 0,
         pendingAreaAlertsLength: 0
      };
   }

   enterSearch = (event) => {
      //Check if the button pressed is enter and if the button search is enabled
      if (
         event.keyCode === 13 &&
         this.isMonthsActive() &&
         this.isFiltersSelected()
      ) {
         event.preventDefault();
         this.handleSearchFilter();
      }
   };

   getBadgeStatusSku = () => {
      const url = SKU_DISCO_ALERT;
      fetch(url, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 401) {
               this.props.logout();
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               return this.setState(() => {
                  return {
                     discoLength: data.numDisco,
                     removedLength: data.numRemoved,
                  };
               });
            }
         })
         .catch((err) => {
            return console.error(err);
         });
   };

   getPendingAreaAlertsLength = () => {
      let url = urlList.GET_PENDING_AREA_ALERTS_LENGTH;
      fetch(url, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 401) {
               this.props.logout();
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               return this.setState(() => {
                  return {
                     pendingAreaAlertsLength: data,
                  };
               });
            }
         })
         .catch((err) => {
            return console.error(err);
         });
   };

   componentDidMount = () => {
      this.props.getInitialFiltersList();
      this.getBadgeStatusSku();
      this.getPendingAreaAlertsLength();

      document.addEventListener("keyup", this.enterSearch);

   };

   componentWillUnmount = () => {
      document.removeEventListener("keyup", this.enterSearch);
   };

   setFilterHome = (type, opts) => {
      if (!this.state.isChanged) {
         this.props.darkenSearchResults();
         this.setState({
            isChanged: true,
         });
      }
      this.setState({
         error: false,
         error_message: "",
      });

      let selectedFilters = _.cloneDeep(this.props.selectedFilters);

      switch (type) {
         case "months":
            selectedFilters.months = opts;
            break;
         case "planningChannel":
            selectedFilters.planningChannel = opts;
            break;
         case "brand":
            selectedFilters.brand = opts;
            break;
         case "commodities":
            selectedFilters.commodities = opts;
            break;
         case "countryList":
            selectedFilters.countryList = opts;
            break;
         case "moduleList":
            selectedFilters.moduleList = opts;
            break;
         default:
      }
      this.props.setSelectedFilters(selectedFilters);
   };

   handleSearchFilter = () => {
      let selectedFilters = this.props.selectedFilters;
      this.props.sendHomeFilters(selectedFilters);
      this.setState({
         isChanged: false,
      });
   };

   renderOptions = (optionType) => {
      //make obj for react-select options
      var allOptions = [];
      if (
         this.state[optionType] !== undefined &&
         this.state[optionType].length > 0
      ) {
         for (var i = 0; i < this.state[optionType].length; i++) {
            let obj = {
               value: this.state[optionType][i],
               label: this.state[optionType][i],
            };
            allOptions.push(obj);
         }
      }
      return allOptions;
   };

   isFiltersSelected = () => {
      let { selectedFilters } = this.props;

      const isCountryListActive = selectedFilters.countryList.length !== 0;
      const isBrandActive = selectedFilters.brand.length !== 0;
      const isPlanningChannelListActive =
         selectedFilters.planningChannel.length !== 0;
      const isCollectionListActive = selectedFilters.commodities.length !== 0;
      return (
         isCountryListActive ||
         isBrandActive ||
         isPlanningChannelListActive ||
         isCollectionListActive
      );
   };
   isMonthsActive = () => {
      let { selectedFilters } = this.props;

      return (
         selectedFilters.months && selectedFilters.months.constructor === Object
      );
   };

   render() {
      // state decomposition
      let { planningChannels, brand, countries, months, commodities } = this.props.filters;

      let { selectedFilters } = this.props;

      let checkEndCalls =
         this.props.isModComplete &&
         this.props.isMacroComplete &&
         this.props.isClusterComplete;
      let checkResults = checkEndCalls
         ? this.props.results &&
         this.props.results.clusterList &&
         this.props.results.clusterList.length < 1 &&
         this.props.results.macroFamilyList &&
         this.props.results.macroFamilyList.length < 1 &&
         this.props.results.moduleList &&
         this.props.results.moduleList.length < 1
         : false;

      const isEnabledSearch = this.isMonthsActive() && this.isFiltersSelected();

      const canSeeDiscontinuedSkuButton =
         isSubmitUser(this.props.auth.grants) ||
         isAdmin(this.props.auth.grants);
      
      return (
         <React.Fragment>
            {this.props.isFiltersOpen || checkResults ? (
               <div
                  className="col-3 sidebar"
                  style={{ overflowY: "auto", overflowX: "hidden" }}
               >
                  <div className="filters " id="filters">
                     <header className="sidebar-header col-12">
                        <div className="vector-container">
                           <a className="vector" />
                           <div className="tooltip-home">
                              <div>
                                 <a href={FRONT_OFFICE_URL}>Frontoffice</a>
                              </div>
                              <div>
                                 <h4>BackOffice</h4>
                              </div>
                              <div>
                                 <a onClick={this.props.logout}>Logout</a>
                              </div>
                           </div>
                        </div>
                        <h1>
                           <span>Stars</span>
                           <span>Assortment</span>
                        </h1>
                     </header>
                     <div className="sidebar-content">
                        <div className="top-filters">
                           <h2>
                              <span className="number">1</span> choose months:
                           </h2>
                           <CustomSelect
                              isLoading={!months.length}
                              options={months ? months : []}
                              placeholder="Months"
                              handleChange={(e) =>
                                 this.setFilterHome("months", e)
                              }
                              value={selectedFilters.months}
                           />
                        </div>
                        <div className="bottom-filters">
                           <h2>
                              <span className="number">2</span> refine your
                              search:
                           </h2>
                           <CustomSelect
                              isLoading={
                                 planningChannels &&
                                 planningChannels.length === 0 &&
                                 !!selectedFilters.months
                              }
                              disabled={!this.isMonthsActive()}
                              options={planningChannels ? planningChannels : []}
                              placeholder="Planning Channel"
                              handleChange={(e) =>
                                 this.setFilterHome("planningChannel", e)
                              }
                              value={selectedFilters.planningChannel}
                              isMulti
                           />
                           <CustomSelect
                              isLoading={
                                 brand &&
                                 brand.length === 0 &&
                                 !!(
                                    selectedFilters.planningChannel &&
                                    selectedFilters.planningChannel.length > 0
                                 )
                              }
                              options={brand ? brand : []}
                              placeholder="Brand"
                              disabled={!this.isMonthsActive()}
                              handleChange={(e) =>
                                 this.setFilterHome("brand", e)
                              }
                              value={selectedFilters.brand}
                              isMulti
                           />
                           <CustomSelect
                              isLoading={
                                 commodities &&
                                 commodities.length === 0 &&
                                 !!(commodities && commodities.length > 0)
                              }
                              options={commodities}
                              placeholder="collection"
                              disabled={!this.isMonthsActive()}
                              handleChange={(e) =>
                                 this.setFilterHome("commodities", e)
                              }
                              isMulti
                              value={selectedFilters.commodities}
                           />
                           <CustomSelect
                              isLoading={
                                 countries &&
                                 countries.length === 0 &&
                                 !!(
                                    selectedFilters.brand &&
                                    selectedFilters.brand.length > 0
                                 )
                              }
                              options={countries ? countries : []}
                              placeholder="Stars zone"
                              disabled={!this.isMonthsActive()}
                              handleChange={(e) =>
                                 this.setFilterHome("countryList", e)
                              }
                              isMulti
                              value={selectedFilters.countryList}
                           />
                           <div>
                              <Button
                                 btnClass="btn-medium center btn-search btn-white"
                                 icon="icon-arrow1"
                                 iconAfter
                                 handleClick={this.handleSearchFilter}
                                 disabled={!isEnabledSearch}
                              >
                                 Search
                              </Button>
                              {this.state.error ? (
                                 <div className="error-container">
                                    <p className="msg-error">
                                       {this.state.error_message}
                                    </p>
                                 </div>
                              ) : null}
                           </div>
                        </div>
                     </div>
                     <footer className="sidebar-footer">
                        <div className="sidebar-footer-content">
                           {/* <LinkedButton
                              link="/manageRelease"
                              btnClass="btn-medium btn-white"
                           >
                              Manage Release
                           </LinkedButton> */}
                           {this.props.auth &&
                              this.props.auth.grants &&
                              isAdmin(this.props.auth.grants) ? (
                              <div className="btn-cont">
                                 <LinkedButton
                                    link="/manageUser"
                                    btnClass="btn-medium btn-white"
                                 >
                                    Manage User
                                 </LinkedButton>
                              </div>
                           ) : null}
                           {this.props.auth &&
                              this.props.auth.grants &&
                              isAdmin(this.props.auth.grants) ? (
                              <div className="btn-cont">
                                 <LinkedButton
                                    link="/manageBrand"
                                    btnClass="btn-medium btn-white"
                                 >
                                    Manage Brand
                                 </LinkedButton>
                              </div>
                           ) : null}
                           <div className="btn-cont">
                              {/* <LinkedButton
                                 link="/history"
                                 btnClass="btn-medium btn-white"
                              >
                                 Approve Cluster
                              </LinkedButton> */}
                              <LinkedButton
                                 link="/statistics"
                                 btnClass="btn-medium btn-white"
                                 disabled={
                                    !this.props.selectedFilters.brand.length ||
                                    !(
                                       this.props.selectedFilters.months &&
                                       this.props.selectedFilters.months
                                          .constructor === Object
                                    )
                                 }
                              >
                                 Statistics Page
                              </LinkedButton>
                           </div>
                           <div className="btn-cont">
                              <LinkedButton
                                 link="/export"
                                 btnClass="btn-medium btn-white"
                              >
                                 Export Data
                              </LinkedButton>
                           </div>
                           {this.props.auth &&
                              this.props.auth.grants &&
                              canImportData(this.props.auth.grants) ? (
                              <div className="btn-cont">
                                 <LinkedButton
                                    link="/upload"
                                    btnClass="btn-medium btn-white"
                                 >
                                    Import Data
                                 </LinkedButton>
                              </div>
                           ) : null}

                           {canSeeDiscontinuedSkuButton ? (
                              <div className="btn-cont">
                                 <LinkedButton
                                    link="/disco-remove-area"
                                    btnClass="btn-medium btn-white"
                                 >
                                    <div className="bell-icon-container">
                                       {this.state.discoLength > 0 ? (
                                          <span className="bell-red" />
                                       ) : null}
                                       {this.state.removedLength > 0 ? (
                                          <span className="bell-orange" />
                                       ) : null}
                                    </div>
                                    DISCO/REMOVED AREA
                                 </LinkedButton>
                              </div>
                           ) : null}

                           <div className="btn-cont">
                              <LinkedButton
                                 link="/pending-area"
                                 btnClass="btn-medium btn-white"
                              >
                                 <div className="bell-icon-container">
                                       {this.state.pendingAreaAlertsLength > 0 ? (
                                          <span className="bell-red" />
                                       ) : null}
                                    </div>
                                 Pending Area
                              </LinkedButton>
                           </div>

                           <div className="btn-cont">
                              <LinkedButton
                                 link="/massive-edit-wi"
                                 btnClass="btn-medium btn-white"
                                 onClick={() => {
                                    this.props.setResetRowsToSendMassiveEdit(
                                       {}
                                    );
                                 }}
                              >
                                 MASSIVE EDIT
                              </LinkedButton>
                           </div>

                           <div className="btn-cont">
                              <LinkedButton
                                 link="#"
                                 btnClass="btn-medium btn-white"
                                 onClick={this.props.openClientClassificationPopup}
                              >
                                 client classification
                              </LinkedButton>
                           </div>
                        </div>
                     </footer>
                  </div>
                  <LeftBar
                     label="Close Filter"
                     handleClick={() => this.props.toggleHomeFilters(false)}
                  />
               </div>
            ) : null}
            {
               this.props.clientClassificationPopupVisible &&
               <AddClientClassificationPopup
                  onClose={this.props.closeClientClassificationPopup}
               />
            }
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      auth: state.authentication,
      filters: state.filter.filterHome,
      releaseList: state.release.releaseList,
      selectedFilters: state.filter.selectedFilters,
      isFiltersOpen: state.filter.isFiltersOpen,
      results: state.filter.results,
      isModComplete: state.filter.isModComplete,
      isMacroComplete: state.filter.isMacroComplete,
      isClusterComplete: state.filter.isClusterComplete,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getInitialFiltersList: () => dispatch(actions.getInitialFiltersList()),
      getBrandList: (filters) => dispatch(actions.getBrandList(filters)),
      getCountry: (filters) => dispatch(actions.getCountry(filters)),
      getPlanningChannel: (filters) =>
         dispatch(actions.getPlanningChannel(filters)),
      getReleaseList: () => dispatch(actions.getReleaseList()),
      sendHomeFilters: (filters) => dispatch(actions.sendHomeFilters(filters)),
      setSelectedFilters: (filters) =>
         dispatch(actions.setSelectedFilters(filters)),
      toggleHomeFilters: (value) => dispatch(actions.toggleHomeFilters(value)),
      darkenSearchResults: () => dispatch(actions.darkenSearchResults()),
      setReleaseStatus: (status) => dispatch(actions.setReleaseStatus(status)),
      logout: () => dispatch(actions.logout()),
      setResetRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
         dispatch(actions.setResetRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
